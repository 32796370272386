@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Raleway";
  src: url("./assets/font/Raleway-VariableFont_wght.ttf")
    format("truetype-variations");
  font-weight: 1 999;
}

body {
  margin: 0;
  font-family: "Raleway";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before,
.slick-next:before {
  color: #faca21 !important;
}

.slick-dots li button:before {
  color: #faca21 !important;
  width: 35px !important;
  height: 35px !important;
}

/* the slides */
.slick-slide {
  padding: 0 10px !important;
  overflow: hidden;
}

/* the parent */
.slick-list {
  padding: 0 -10px !important;
}
